const zh = {
  "MAKE_THE": "让设计",
  "DESIGN_EASIER": "变得更简单",
  "UPLOAD_SPACE_PHOTO__CONFIGURE_RENDERING_PARAMETERS_1": "上传空间照片 设置渲染参数",
  "UPLOAD_SPACE_PHOTO__CONFIGURE_RENDERING_PARAMETERS_2": "生成你的专属设计",
  "GET_YOUR_DESIGN": "获取您的设计",
  "FREE_TRIAL": "免费试用",
  "ENJOY_COMPLIMENTARY_CREDITS_DAILY": "每天享受 {n} 个免费次数",
  "YOUR_FREE_QUOTA_FOR_TODAY_HAS_BEEN_USED_UP": "您今天的免费次数已用完",
  "REGISTER_ACCOUNT": "注册账户",
  "CLAIM_100_PROFESSIONAL_RENDERING_CREDITS_FOR_FREE": "免费领取 100 个专业级渲染次数！",
  "FOR_MORE_QUESTIONS_PLEASE_CONTACT": "更多问题，请联系客服",
  "LOGIN_IN": "登入",
  "STYLE": "风格",
  "JAPANESE_DESIGN": "日式设计",
  "MINIMALIST": "极简风格",
  "ART_NOUVEAU": "新艺术",
  "BOHEMIAN": "波西米亚",
  "INDUSTRIAL": "工业",
  "SCANDINAVIAN": "北欧风",
  "BAROQUE": "巴洛克",
  "COTTAGECORE": "田园风",
  "MAXIMALIST": "极致风格",
  "MIDCENTURY_MODERN": "中世纪现代",
  "NEOCLASSIC": "新古典主义",
  "VINTAGE": "复古",
  "BIOPHILIC": "亲自然",
  "TROPICAL": "热带",
  "ZEN": "禅意",
  "COASTAL": "海岸",
  "FARMHOUSE": "农舍",
  "FRENCH_COUNTRY_DESIGN": "法式乡村",
  "RUSTIC": "乡村风",
  "SKI_CHALET": "滑雪小屋",
  "TRIBAL": "部落",
  "ART_DECO": "装饰艺术",
  "GAMING_ROOM": "游戏房间",
  "CHINESE_NEW_YEAR": "中国新年",
  "CHRISTMAS": "圣诞节",
  "EASTER": "复活节",
  "HALLOWEEN": "万圣节",
  "CYBERPUNK": "赛博朋克",
  "ROOM": "房间",
  "OFFICE": "办公室",
  "WORKSHOP": "工作室",
  "MEETING_ROOM": "会议室",
  "COWORKING_SPACE": "共享办公空间",
  "RESTAURANT": "餐厅",
  "COFFEE_SHOP": "咖啡厅",
  "CLOTHING_STORE": "服装店",
  "FITNESS_GYM": "健身房",
  "TOILET": "洗手间",
  "HOTEL_LOBBY": "酒店大堂",
  "HOTEL_ROOM": "酒店客房",
  "HOTEL_BATHROOM": "酒店浴室",
  "EXHIBITION_SPACE": "展览空间",
  "ONSEN": "温泉",
  "LIVING_ROOM": "客厅",
  "DINING_ROOM": "餐厅",
  "BEDROOM": "卧室",
  "CHILDREN_ROOM": "儿童房",
  "KITCHEN": "厨房",
  "BATH_ROOM": "浴室",
  "STUDY_ROOM": "学习室",
  "HOME_OFFICE": "家庭办公室",
  "ATTIC": "阁楼",
  "WALK_IN_CLOSET": "步入式衣帽间",
  "MUDROOM": "储物室",
  "DROP_ZONE": "闲置区",
  "OUTDOOR_PATIO": "室外庭院",
  "OUTDOOR_GARDEN": "室外花园",
  "OUTDOOR_POOL_AREA": "室外游泳区域",
  "HOUSE_EXTERIOR": "房屋外观",
  "MODE": "模式",
  "VIRTUAL_STAGING": "虚拟层次",
  "INTERIOR_DESIGN": "室内设计",
  "RESOLUTION": "尺寸",
  "LOW": "高",
  "HIGH": "低",
  "GENERATE": "生成",
  "RENDER_FAILURE_TIP:INSUFFICIENT_NUMBER_OF_RENDERINGS": "渲染失败提示：渲染次数不足",
  "RENDERING": "渲染中...",
  "CANCEL": "取消",
  "TIPS": "提示",
  "DO_YOU_STILL_WANT_TO_CANCEL": "渲染中。如果您选择取消，当前的渲染次数将不会退还，仍会被扣除。您还想取消吗？",
  "CONFIRM": "确认",
  "CREATED_TIME": "创建时间",
  "ROOM_COWORKING_SPACE": "风格: 共享办公空间",
  "MODE_INTERIOR_DESIGN": "模式: 室内设计",
  "RESOLUTION_HIGH": "尺寸: 高",
  "STYLE_CYBERPUNK": "风格: 赛博朋克",
  "SHARE_LINK": "分享链接",
  "COPY": "复制",
  "TODAY": "今天",
  "YESTERDAY": "昨天",
  "PREVIOUS_7_DAYS": "7天之内",
  "PREVIOUS_30_DAYS": "30天之内",
  "EARLIER": "更早",
  "RENDER_AGAIN": "再次渲染",
  "ARE_YOU_SURE_YOU_WANT_TO_RENDER_AGAIN": "你确定要再次渲染吗？",
  "TWO_FACTOR_AUTHENTICATION_ENABLED_DISABLED": "启用/禁用双因素身份验证",
  "ENGLISH": "英语",
  "LOGOUT": "退出登录",
  "PREFERENCE": "偏好设置",
  "AUTHENTICATION": "认证",
  "MEMBERS": "会员",
  "LOGIN_HISTORY": "登录历史",
  "AVAILABLE_CREDIT": "次数",
  "EMAIL:": "邮箱: ",
  "PASSWORD": "密码",
  "REVISE": "修改",
  "OLD_PASSWORD": "旧密码",
  "NEW_PASSWORD": "新密码",
  "YOUR_NEW_PASSWORD": "您的新密码",
  "CONFIRM_PASSWORD": "确认密码",
  "SAVE": "保存",
  "RESET": "取消",
  "PLEASE_COMPLETE_THE_INFORMATION": "请将信息填写完整！",
  "PLEASE_ENTER_YOUR_OLD_PASSWORD": "请输入旧密码！",
  "PLEASE_ENTER_YOUR_NEW_PASSWORD": "请输入新密码！",
  "PLEASE_CONFIRM_YOUR_NEW_PASSWORD": "请确认新密码！",
  "PASSWORD_COMPLEX_INFO": "密码必须为 8-16 个字符，并包含数字和字母/特殊字符",
  "THE_TWO_PASSWORDS_YOU_ENTERED_DO_NOT_MATCH": "确认密码不匹配，请重新输入",
  "LANGUAGE": "语言",
  "ACTIVITIES": "活动",
  "NO_DATE": "-",
  "AUTOMATIC_ALLOCATION_FOR_NEW_ACCOUNT": "新账户自动分配",
  "VERSION": "版本",
  "TOP_UP": "充值",
  "PLEASE_ENTER_INVITE_EMAIL": "请输入邀请电子邮件",
  "INVITATIONS": "邀请",
  "THE_ENTERED_EMAIL_IS_INVALID": "输入的电子邮件无效",
  "EMAIL_FORMAT_INVALID": "电子邮件格式无效，请检查后重试",
  "INVITEE_CANNOT_BE_INVITER": "被邀请者不能成为邀请者，请检查后重试",
  "YOUR_REFERRAL_LINK": "您的推荐链接",
  "COPY_LINK": "复制链接",
  "TWO_FACTOR_AUTHENTICATION": "双因素身份验证",
  "USING_2FA": "*使用一次性密码验证器 (2FA)，在手机或电脑上启用双因素身份验证功能",
  "NOTICE": "注意事项",
  "PLEASE_INSTALL_A_SOFTWARE_TOKEN_INSTALLER_AUTHENTICATOR_INFO": "请从应用程序库中安装软件令牌安装程序，如 Google Authenticator，然后扫描此二维码，更多信息请参阅用户手册。",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_1": "无法扫描二维码？",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_2": "要手动添加条目，请在移动应用程序中提供以下信息",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_3": "帐户： greypanel",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_4": "密钥：",
  "CANNOT_SCAN_THE_QR_CODE_ADD_MANUALLY_PART_5": "验证方法： 基于时间的验证",
  "PIN_CODE": "密码",
  "REGISTER_WITH_THE_DUAL_AUTHENTICATION_APPLICATION": "注册双重认证应用程序",
  "SEARCH": "搜索",
  "INVITE_YOUR_FRIENDS": "邀请好友",
  "NAME": "名称",
  "TRIGGER_TIME": "触发时间",
  "ACTION": "操作",
  "NO_DATA": "无数据",
  "CREDITS": "积分",
  "ASSOCIATION": "关联",
  "DISASSOCIATE": "解除关联",
  "ASSOCIATE_MEMBERS": "准会员",
  "RECEIVED_CREDITS": "收到的积分",
  "BROWSER": "浏览器",
  "ACCESS_ON": "开启访问",
  "LOGIN_IP": "登录 IP",
  "WELCOME_BACK_PLEASE_LOGIN_TO_YOUR_ACCOUNT": "欢迎回来！请登录您的账户。",
  "ACCOUNT": "账户",
  "PLEASE_ENTER_YOUR_EMAIL": "请输入您的电子邮件",
  "PLEASE_ENTER_YOUR_PASSWORD": "请输入密码",
  "REMEMBER_ME": "记住我的密码",
  "FORGOT_PASSWORD": "忘记密码",
  "LOGIN": "登录",
  "HAVE_NO_ACCOUNT": "还没有账户？",
  "SIGN_UP_HERE": "在此注册。",
  "FOR_MORE_INFORMATION_ABOUT_KAINO_AI_ACCOUNT": "有关 KainoAI 账户的更多信息、",
  "CLICK_HERE": "点击此处。",
  "SIGN_IN_WITH_A_THIRD_PARTY": "使用第三方登录",
  "SIGN_IN_WITH_APPLE": "使用 Apple 登录",
  "SIGN_IN_WITH_GOOGLE": "使用谷歌登录",
  "SIGN_IN_WITH_FACEBOOK": "使用 Facebook 登录",
  "PRIVACY_POLICY_PART_1": "我们致力于保护您的隐私。KainoAI 使用您提供给我们的信息，就我们的相关内容、产品和服务与您联系。您可以随时取消订阅这些信息。更多信息，请查看我们的",
  "PRIVACY_POLICY": "隐私政策",
  "LOGIN_FAILED_ERROR_MESSAGE_THE_USER_IS_NOT_ACTIVATED": "登录失败错误信息：用户未激活，请联系管理员寻求帮助",
  "LOGIN_FAILED_ERROR_MESSAGE_THE_ACCOUNT_NEED_MODIFY_PASSWORD_AFTER_THIRD_PARTY_LOGIN": "登录失败错误信息：第三方登录后，账户需要修改密码",
  "LOGIN_FAILED_ERROR_MESSAGE_USERNAME_OR_PASSWORD_IS_INCORRECT": "登录失败错误消息：用户名或密码不正确，请检查后重试",
  "RESET_PASSWORD": "重置密码",
  "ENTER_THE_EMAIL_ASSOCIATED_TO_RESET_YOUR_PASSWORD": "输入与您的帐户相关联的电子邮件，我们将发送一封电子邮件，说明如何重置密码。",
  "EMAIL": "邮件",
  "RESEND_INSTRUCTIONS": "重新发送说明",
  "BACK": "返回",
  "PLEASE_SIGN_UP_YOUR_ACCOUNT": "请注册您的帐户。",
  "E_MAIL": "电子邮件",
  "YOUR_PASSWORD": "密码",
  "SIGN_UP": "注册",
  "ALREADY_HAVE_AN_ACCOUNT": "已经有账户？",
  "LOGIN_HERE": "在此登录。",
  "PLEASE_ENTER_VALID_EMAIL": "输入的电子邮件无效",
  "PLEASE_CONFIRM_YOUR_PASSWORD": "请确认密码",
  "THE_TWO_PASSWORDS_YOU_ENTERED_DO_NOT_MATCH,_PLEASE_CHECK_AND_RESUBMIT": "您输入的两个密码不匹配，请检查并重新提交",
  "REGISTRATION_SUCCESSFUL": "注册成功！",
  "WE_HAVE_SENT_EMAIL_TO_YOU": "谢谢。我们已经向您发送了电子邮件。",
  "PLEASE_CLICK_ON_THE_LINK_IN_THAT_EMAIL_TO_ACTIVATE_YOUR_ACCOUNT": "请点击邮件中的链接激活您的账户。",
  "PLEASE_CLICK_THE_BUTTON_BELOW_TO_RESEND": "如果您没有收到电子邮件，请点击下面的按钮重新发送。",
  "RESEND": "重新发送",
  "WELCOME_TO_LOGIN_YOUR_KAINO_AI_ACCOUNT": "欢迎登录您的 KainoAI 账户",
  "SUCCESSFUL_LOGIN": "成功登录",
  "TWO_FACTOR_AUTHENTICATION_2FA": "双因素验证 (2FA)",
  "A_MESSAGE_CONTAINING_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_GOOGLE_AUTHENTICATOR": "包含验证码的信息已发送到您的 Google Authenticator",
  "6_DIGIT_SECURITY_CODE_TO_CONTINUE": "请输入 6 位安全代码以继续",
  "VERIFY": "验证",
  "CAMERA":"拍照",
  "PHOTO_LIBRARY":"相册",
  "PASSWORD_CANNOT_BE_THE_SAME":"新旧密码不能一致",
  "CLOSE_ACCOUNT":"注销账号",
  "SURE_TO_DELETE_ACCOUNT":"您确定要删除您的帐户吗?这将永久删除您的帐户。",
  "DELETE_IMAGE":"删除图片",
  "SURE_TO_DELETE_IMAGE":"你确定要删除这张图片吗?",
  "SURE_TO_LOGOUT":"确定要登出吗?",
  "NO_CREDIT":"次数已用完",
  "NO_CREDIT_DESC":   "您今日的免费额度已经用完，注册账号可获得更多免费次数，是否进行注册？",
  "START_RENDER":"开始",
  "ORIGINAL":"原始图",
  "NEW":"渲染图",
  "ANNOUNCEMENT":"公告",
  "NEW_VERSION_AVAILABLE":"新版本可用",
  "UPDATE":"更新",
  "ASSOCIATE_SUCCESS":"关联成功",
  "AGREE_BUILD_ASSOCIATION":"确定同意 {email} 的建立关联申请吗？",
  "AFTER_AGREE_ASSOCIATION":"同意后将与对方组织建立关联，对方可以为你分配Credits",
  "DISABLE_2FA":"确定关闭双重认证功能？",
  "GET_LOGIN_CODE":"从 Google Authenticator 获取验证码。",
  "NEXT":"下一步",
  "ENTERCODE":"输入验证码",
  "TWOFA_ON":"双重认证已启用",
  "ADDITIONAL_CODE":   "当您在我们不认识的设备上登录时，除密码外，我们还会要求您输入验证码。",
  "DONE":"完成",
  "AVAILABLE_CREDITS":"可用次数",
  "SURPLUS_CREDIT":"剩余次数",
  "SPENT":"已使用",
  "ALL":"全部",
  "CHOOSE_MEMBER":"选择会员",
  "INVITE_MEMBER":"邀请会员",
  "CHOOSE_CREDITS":"选择次数",
  "ISSUE_CREDITS":"分发 {n} 次",
  "MULTIPLES_OF_100_CAN_BE_ALLOCATED_EACH_TIME":"*每次可分配的数量为100的倍",
  "NO_ENOUGH_CREDITS_CANNOT_ASSOCIATION":"剩余次数不足，无法成功分配",
  "EMAIL_SENT":"邮件已发送",
  "EMAIL_SENT_DES1":"谢谢，我们已经将邮件发送",
  "EMAIL_SENT_DES2":"请通过您的邮箱验证修改密码",
  "EMAIL_SENT_DES3":"如果您没有收到邮件，请点击重新发送",
  "RESEND_COOLING":"{n}秒后重新发送",
  "USERNAME_OR_PASSWORD_CANNOT_BE_EMPTY":"用户名或邮箱不能为空",
  "MAILBOX_NOT_ACTIVATED":"邮箱未激活, 请激活后再次尝试",
  "SEND_VERIFICATION_EMAIL_AGAIN":"重新发送验证邮件",
  "PRIVACY_LONG_TEXT":   "我们致力于保护您的隐私。KainoAI会使用您提供给我们的信息，就我们的相关内容、产品和服务与您联系。您可以随时取消订阅这些信息。更多信息，请查看我们的",
  "PRIVACY_LONG_LINK":"隐私协议",
  "AGENT":"代理",
  "RECEVED_CREDITS":"已获得次数",
  "COPY_SUCCESS":"复制成功",
  "DATA_PRIVACY_MARKETING_POLICYKAINOAI":"数据隐私和营销政策 - KainoAI",
  "WE_ARE_COMMITTED_TO_ENSURING_THE_PRIVACY_AND_SECURITY_OF_YOUR_PERSONAL_INFORMATION":   "我们致力于确保您个人信息的隐私和安全。本隐私政策概述了我们如何收集、使用和保护您的数据。通过使用我们的服务，您同意接受此处描述的条款和做法。",
  "INFORMATION_WE_COLLECT":"1. 我们收集的信息",
  "WE_MAY_COLLECT_THE_FOLLOWING_TYPES_OF_INFORMATION":   "我们可能收集以下类型的信息：",
  "PERSONAL_INFORMATION_NAME_EMAIL_ADDRESS_CONTACT_DETAILS":   "个人信息：姓名、电子邮件地址、联系方式。",
  "DESIGN_PREFERENCES_INFORMATION_YOU_PROVIDE_REGARDING_YOUR_DESIGN_NEEDS_AND_PREFERENCES":   "设计偏好：您提供的有关设计需求和偏好的信息。",
  "USAGE_DATA_INTERACTION_DATA_SUCH_AS_YOUR_INTERACTIONS_WITH_OUR_WEBSITE_AND_DESIGN_TOOLS":   "使用数据：交互数据，例如您与我们的网站和设计工具的互动。",
  "WE_COLLECT_THIS_INFORMATION_TO_DELIVER_PERSONALIZED_AND_EFFICIENT_GRAPHIC_DESIGN_SOLUTIONS_TO_YOU":   "我们收集此信息以向您提供个性化和高效的图形设计解决方案。",
  "HOW_WE_USE_YOUR_INFORMATION":"2. 我们如何使用您的信息",
  "WE_USE_YOUR_INFORMATION_TO":"我们使用您的信息来：",
  "CREATE_AND_DELIVER_GRAPHIC_DESIGNS_BASED_ON_YOUR_PREFERENCES":   "基于您的偏好创建和交付图形设计。",
  "COMMUNICATE_WITH_YOU_ABOUT_YOUR_DESIGN_PROJECTS_AND_UPDATES":   "与您沟通有关您的设计项目和更新的信息。",
  "IMPROVE_OUR_SERVICES_AND_OFFERINGS_BASED_ON_USER_INTERACTIONS":   "使用数据：交互数据，例如您与我们的网站和设计工具的互动。",
  "DATA_SECURITY":"3. 数据安全性",
  "WE_TAKE_THE_SECURITY_OF_YOUR_DATA_SERIOUSLY":   "我们认真对待您数据的安全性。我们采取技术和组织措施，以防止您的信息遭到未经授权的访问、丢失、滥用或更改。然而，请注意，通过互联网传输数据的任何方法都不是完全安全的。",
  "SHARING_OF_INFORMATION":"4. 信息共享",
  "WE_DO_NOT_SHARE_YOUR_PERSONAL_INFORMATION_WITH_THIRD_PARTIES_FOR_MARKETING_PURPOSES":   "我们不会为营销目的与第三方分享您的个人信息。但是，我们可能与以下方分享您的信息：",
  "OUR_SERVICE_PROVIDERS_WHO_ASSIST_US_IN_DELIVERING_OUR_DESIGN_SERVICES":   "协助我们提供设计服务的服务提供商。",
  "LEGAL_AND_REGULATORY_AUTHORITIES":"根据法律要求的法律和监管当局。",
  "COOKIES_AND_TRACKING_TECHNOLOGIES":"5. Cookie和跟踪技术",
  "OUR_WEBSITE_AND_SERVICES_MAY_USE_COOKIES":   "我们的网站和服务可能使用Cookie和类似的跟踪技术来增强您的浏览体验并收集使用信息。您可以调整浏览器设置以管理Cookie偏好。",
  "YOUR_CHOICES":"6. 您的选择",
  "YOU_HAVE_THE_RIGHT_TO":"您有以下权利：",
  "ACCESS_CORRECT_OR_DELETE_YOUR_PERSONAL_INFORMATION":   "访问、更正或删除您的个人信息。",
  "OPT_OUT_OF_RECEIVING_MARKETING_COMMUNICATIONS":"选择不接收营销通信。",
  "RAISE_OBJECTIONS_OR_RESTRICT_THE_PROCESSING_OF_YOUR_DATA":   "提出异议或限制数据处理。",
  "TO_EXERCISE_THESE_RIGHTS_PLEASE_CONTACT_US_AT":`要行使这些权利，请通过$enquiry@kainoai.com与我们联系。`,
  "CHILDREN_PRIVACY":"7. 儿童隐私",
  "OUR_SERVICES_ARE_NOT_INTENDED_FOR_INDIVIDUALS_UNDER_THE_AGE_OF":   "我们的服务不面向[12]岁以下的个人。我们不会故意收集儿童的个人信息。",
  "CHANGES_TO_PRIVACY_POLICY":"8. 隐私政策的更改",
  "WE_MAY_UPDATE_THIS_PRIVACY_POLICY_PERIODICALLY_TO_REFLECT_CHANGES_IN_OUR_PRACTICES_AND_SERVICES":   "我们可能定期更新本隐私政策，以反映我们的做法和服务的变化。修订版本将与生效日期一起发布在我们的网站上。",
  "FOR_ANY_INQUIRIES_ABOUT_OUR_PRIVACY_POLICY_OR_DATA_PRACTICES":`有关我们的隐私政策或数据实践的任何查询，请通过enquiry@kainoai.com与我们联系。您的隐私对我们很重要，我们致力于解决您可能有的任何疑虑。`,
  "KAINOAI_OPERATION_TEAM":"KainoAI运营团队",
  "CONTACT_EMAIL":"联系电子邮件：",
  "TWOFA":"双重验证",
  "HISTORY":"历史记录",
  "MORE":"更多",
  "NOW":" ", //中文状态下为空字符     英文状态下翻译为now
  "SAVE_SUCCESS":"保存成功",
  "TASK_EXECUTED_SUCCESSFULLY":"渲染完成",
  "TASK_EXECUTION_FAILURE":"渲染失败",
  "UUID_INVALID":"uuid无效",
  "RENDER_SUCCESS":"渲染成功",
  "CANCEL_RENDER":   "渲染进行中, 如果您选择取消, 当前渲染的次数不会返还, 会依然扣除。您确定要继续执行取消操作吗？",
  "SELECT_STYLE":"请选择一个风格",
  "SELECT_ROOM":"请选择一个房间类型",
  "CHANGE_PICTURE":"替换照片",
  "CHOOSE_STYLE":"选择风格",
  "CHOOSE_ROOM":"选择房间",
  "EMPTY":"空荡荡的...",
  "PROFILE":"个人主页",
  "RENDER_HISTORY":"渲染历史",
  "RENDER_DETAIL":"渲染详情",
  "PREVIEW":"预览",
  "DELETE_CONFIRM_TEXT1":"确认删除所选内容吗？此操作不可撤销。",
  "DELETE_CONFIRM_TEXT2":"确认删除{name}吗？此操作不可撤销。",
  "API_KEYS":"API 密钥",
  "NOT_HAVE_ANY_API_KEYS":"您当前没有任何 API 密钥",
  "CREATE_USING_THE_BUTTON":"使用下面的按钮创建一个以开始",
  "SEARCH_NAME":"搜索名称",
  "CREATE_API_KEY":"创建 API 密钥",
  "OPTIONAL":"可选的",
  "MY_TEST_KEY":"我的测试密钥",
  "CREATE":"创建",
  "CREATE_API_KEY_SECURITY_DESCRIPTION":"请将此秘密密钥保存在一个安全且可访问的地方，出于安全原因，您将无法通过您的 KainoAI 账户再次查看它。如果您丢失了这个秘密密钥，您将需要生成一个新的。",
  "KEY":"密钥",
  "LAST_USED":"最后使用时间",
  "SECRET_KEY": '秘钥',
  "AUTH_METHOD": '认证方式',
  "SAVE_PASSWORD_2FA":'将您的 AID 密码保存到 Google Authenticator。如果尚未保存，请返回此屏幕继续下一步。',
  "FAIL_TO_LINK_APPLE_STORE":'无法连接到 Apple Store',
  "DROP_AN_IMAGE": "拖放图片/点击选择",
  "RESIDENTIAL": "住宅",
  "COMMERCIAL": "商业",
  "UPLOAD_FAILED": "上传失败",
  "DELETE": "删除",
  "GOOGLE_AUTH_DISABLED": "已关闭谷歌二次验证",
  "GOOGLE_AUTH_ENABLED": "已开启谷歌二次验证",
  "BINDING_FAILED": "绑定失败",
  "DOCUMENT_VERIFICATION_SUCCESSFUL": "文件验证成功",
  "DEAR_USER": "亲爱的用户",
  "YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED": "恭喜，您的账户已成功验证",
  "EMAIL_SENDING_FAILED": "邮件发送失败",
  "AUTOMATICALLY_JUMP_AFTER_3_SECONDS":"3秒后自动跳转到首页，或者点击按钮立即跳转",
  "AUTOMATICALLY_JUMP_AFTER_S": "s后自动跳转",
  "JUMP_NOW": "立即跳转",
  "VERIFICATION_FAILED": "验证失败",
  "VERIFICATION_HAS_EXPIRED": "出现了一些问题，该验证已过期。我们已重新发送激活邮件到您的邮箱，请注意查收。",
  "PARAMETER": "参数",
  "EMAIL_LENGTH_CANNOT_EXCEED_50_CHARACTERS": "邮件长度不能超过50个字符!",
  "UPLOAD_PICTURE": "上传图片",
  "DOWNLOAD": "下载",
  "FULL": "全视图",
  "SENDING":"发送",
  "SUCCESSFUL_DISTRIBUTION": "分配成功",
  "STATUS":"状态",
  "SYSTEM_MAINTENANCE_NOTICE":"系统维护通知",
  "DOES_IT_JUMP_TO_THE_ANDROID_BUNDLE": "是否跳转到Android绑定?",
  "WEB_BINDING": "Web端绑定",
  "ANDROID_BINDING": "Android端绑定",
  "APPLY": "应用",
  "EDIT": "编辑",
  "THE_NAME_CANNOT_BE_EMPTY": "名称不能为空",
  "CREATE_DATE": "创建日期",
  "ADVANCED_PROMPT": "高级提示词",
  "DONT_NEED_THIS_SERVICE": "不再需要使用该服务",
  "BAD_USE_EXPERIENCE": "用户体验不佳",
  "FOUND_A_BETTER_ALTERNATIVE": "找到了更好的替代品",
  "REMARK": "备注",
  "PLEASE_CHOOSE_REASON": "请选择原因",
  "OBJECT_REMOVAL": "擦除",
  "RENDER_FAILURE_TIP":"渲染失败提示",
  PLEASE_RATE_YOUR_SATISFACTION_WITH_THE_RENDERING_BY_KAINOAI:"请评价您对 KainoAI 渲染服务的满意度。",
  VERY_UNSATISFIED:"非常不满意",
  UNSATISFIED:"不满意",
  NEUTRAL:"中性",
  SATISFIED:"满意",
  VERY_SATISFIED:"非常满意",
  SKETCH: "图片转线稿",
  UPLOAD:"上传",
  ERASER:"擦除",
  AUTO:"AI 选区",
  AUTO_SELECTING_AN_ITEM_BY_CLICKING_ON_IT:"通过点击让AI自动选区",
  MANUAL:"手动选区",
  MANUAL_SELECTION_OF_ITEMS_BY_PAINTING:"通过手动选区",
  MULTIPLE_SELECTION:"多点选区",
  AUTOMATIC_ITEM_SELECTION_THROUGH_MULTIPLE_MARKER_POINTS:"通过多个标记点让AI自动选区",
  CONFIRMATION_OF_CHOICE:"确认选择",
  START: "开始",
  CONTRAST:"对比",
  REVOCATION:"撤销",
  RECOVER:"恢复",
  BRUSH_SIZE:"画笔大小",
  DRAG_AND_DROP_THE_IMAGE_HERE_TO_UPLOAD:"将图片拖放到此处进行上传",
  HD_RESTORATION: "高清修复",
  DESIGN: "设计",
  DETAILS:"详情",
  RESTART:"重新开始",
  SHARE:"分享",
  SWIPE_DOWN_TO_VIEW_MODE:"向下滑动查看",
  PLEASE_WAIT:"请稍等",
  GENERATING_THE_DESIGN:"正在生成设计",
  PLEASE_WAIT_A_MOVEMENT:"请稍等片刻",
  CONVERSATION:"对话",
  NO_FREE_TOKEN: "今日免费 Credit 额度已用完, 请注册新账号再使用该服务",
  CLICK_GO: "点击这里前往",
  CREDIT_NO_RELIEF: "您的Credit 额度已用完",
  USE_NO_RELIEF: "用户已用完 Credit",
  ADD_TOKEN: "如需充值请联系客服 {email}",
  MARK_PIC: "请问这张图是您提供的原图(需要被渲染的房间)，还是您期望渲染的最终效果图？",
  NOTE_START_RENDER: "选择原图(需要被渲染的房间)和渲染目标要求后，输入：“开始渲染” 或者 点击",
  CHAT_START_RENDER: "开始渲染",
  RENDER_CANCELLED: "渲染已取消",
  SET_ORIGIN_IMAGE: "设置为原图(需要被渲染的房间)",
  USING_WEB: "使用网络",
  CHAT_GUIDE_LINE_1: "当然！欢迎使用 KainoAI 应用！",
  CHAT_GUIDE_LINE_2: "开始方法如下：",
  CHAT_GUIDE_LINE_3: "上传您的房间照片：",
  CHAT_GUIDE_LINE_4: "首先上传您要重新设计的房间的照片。这有助于我们的 AI 了解您的空间并提供准确的建议。",
  CHAT_GUIDE_LINE_5: "选择您的风格和房间类型：",
  CHAT_GUIDE_LINE_6: "接下来，选择您喜欢的设计风格并指定房间类型。无论是舒适的卧室、现代客厅还是实用的家庭办公室，我们的 AI 都随时准备为您提供帮助。",
  CHAT_GUIDE_LINE_7: "分享您的想法：",
  CHAT_GUIDE_LINE_8: "欢迎分享与我们的 AI 设计师分享您的想法和创意。您提供的细节越多，我们就越能根据您的愿景量身定制设计。一旦您分享了您的偏好，我们的 AI 将根据您的输入开始渲染设计精美的房间。",
  CHAT_GUIDE_LINE_9: "借助尖端的 AI 技术，尽情改造您的空间！",
  STOP_RENDER: "停止渲染",
  ASKING_ANYTHING: "问我任何事情"
}

export default zh;
