import React, { FC } from "react";
import Confirm from "./Confirm";
const H5MadelPlx: FC = () => {
  return (
    <React.Fragment>
      <Confirm />
    </React.Fragment>
  );
};

export default H5MadelPlx;
