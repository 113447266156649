import { Spin } from "antd";
import { FC } from "react";

const LoadingPlx: FC = () => {
  return (
    <Spin
      size="large"
      style={{
        position: "fixed",
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
      }}
    />
  );
};
export default LoadingPlx;
