import { ITrigger } from "@/Hooks/useLoading";
import { ReactNode } from "react";
import { Subject } from "rxjs";
import { filter } from "rxjs/operators";
export enum EInfoType {
  create_resource = "create_resource",
  reset_home_resource = "reset_home_resource",
  delete_confirm_normal = "delete_confirm_normal",
}

interface IInfo {
  type: EInfoType | number;
  value?: any;
}

class Message {
  private info$ = new Subject<IInfo>();
  public createMessage = (inf: IInfo) => {
    this.info$.next(inf);
  };
  public createSubscibe = (type: EInfoType | number) => {
    return this.info$.pipe(filter((info) => info.type === type));
  };

  /**
   * 重置渲染form:仅用于首页重置
   */
  public resetHomeRenderForm = () => {
    this.info$.next({
      type: EInfoType.reset_home_resource,
    });
  };
  /**
   * 全局删除弹窗
   */
  public deleteConfirm = (
    body: ReactNode,
    event: ITrigger,
    config?: {
      okText?: string;
      cancelText?: string;
      width?: number;
      title?: string;
    }
  ) => {
    this.info$.next({
      type: EInfoType.delete_confirm_normal,
      value: {
        body,
        event,
        config,
      },
    });
  };
}

const messageService = new Message();

export default messageService;
