import useUserDetail from '@/Hooks/useUserDetail';
import IconFont from '@/components/IconFont';
import { useLocale } from '@/polyglotplug';
import accountService from '@/store/account/service';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Col, Row, Space, Tooltip } from "antd";
import { FC, useCallback } from "react";
import { useNavigate } from 'react-router-dom';
import "./index.less";

const BusinessCard: FC = () => {
  const userInfo = useUserDetail();
  const navigate = useNavigate();
  const t = useLocale();

  const loginOut = useCallback(() => {
    accountService.logout();
    navigate("/login", { replace: true })
  }, [navigate]);
  const languageR = useCallback(
    (str: any) => {
      if (str) {
        const data: any = {
          zh_CN: "简体中文",
          en_US: "English",
          zh_TW: "繁體中文"
        }
        return data[str];
      }

    },
    [],
  )
  const editUser = useCallback(
    () => {
      navigate("/profile")
    },
    [navigate],
  )

  const goPerson = useCallback((uri: string, key: string) => {
    navigate(`/${uri}`, { state: { key } });
  }, [navigate]);


  return (
    <section className="floating-dropdown-content">
      <div className="floating-header">
        <div>
          <Avatar
            size={65}
            icon={
              <UserOutlined />
            }
          />
        </div>
        <span onClick={editUser}>
          <Tooltip title={<p style={{ color: "#474747" }}>{t("APPLY")}</p>} color="#F7F7F7">
            <IconFont type="icon-bianji" style={{ fontSize: "18px" }} />
          </Tooltip>

        </span>
      </div>
      <div className="floating-main">
        <div className="floating-personinfo">
          <p>{userInfo?.userName}</p>
        </div>
        <div className='floating-upgrade-to-plus'>
          {t("UPGRADE_TO_PLUS")}
          <span>New</span>
        </div>
        <div className="float-there-content">
          <Row align={"middle"} justify="center" gutter={20}>
            {userInfo?.twoFactorAuth === 1 && <Col>
              <Tooltip title={<p style={{ color: "#474747" }}>{t("TWO_FACTOR_AUTHENTICATION")}</p>} color="#F7F7F7">
                <p
                  onClick={() => {
                    goPerson("profile", "authentication");
                  }}
                  className="float-there-box"
                >
                  <IconFont type="icon-jiesuo" style={{ fontSize: "16px" }} />
                </p>
              </Tooltip>
            </Col>}
            <Col>
              <Tooltip title={<p style={{ color: "#474747" }}>{t("PREFERENCE")}</p>} color="#F7F7F7">
                <p
                  onClick={() => {
                    goPerson("profile", "preference");
                  }}
                  className="float-there-box"
                >
                  <IconFont
                    type="icon-gerenzhongxin"
                    style={{ fontSize: "16px" }}
                  />
                </p>
              </Tooltip>

            </Col>

            <Col>
              <Tooltip title={<p style={{ color: "#474747" }}>{t("RENDER_HISTORY")}</p>} color="#F7F7F7">
                <p
                  onClick={() => {
                    navigate("/history")
                  }}
                  className="float-there-box"
                >
                  <IconFont type="icon-shizhong" style={{ fontSize: "16px" }} />
                </p>
              </Tooltip>

            </Col>
            {/* <Col>
              <div
                onClick={() => {
                  goPerson("profile", "plus");
                }}
                className="float-there-box"
              >
                <IconFont type="icon-shizhong" style={{ fontSize: "16px" }} />
              </div>
            </Col> */}
          </Row>
        </div>
        <div className="floating-main-ftcon">
          <Space direction="vertical">
            <div>
              <Space size={24}>

                <IconFont type="icon-dunpai" style={{ fontSize: 18 }} />
                {userInfo?.twoFactorAuth ? (
                  t("GOOGLE_AUTH_ENABLED")
                ) : (
                  t("GOOGLE_AUTH_DISABLED")

                )}


              </Space>
            </div>
            <div>
              <Space size={24}>
                <IconFont
                  type="icon-message-multi-language-fill"
                  style={{ fontSize: 18 }}
                />
                {userInfo?.locale &&
                  languageR(userInfo?.locale)}
              </Space>
            </div>
          </Space>
        </div>
      </div>
      <footer className="floating-footer">
        <Button type="primary" onClick={loginOut}>
          {t("LOGOUT")}
        </Button>
      </footer>
    </section>
  );
}

export default BusinessCard;