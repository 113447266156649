import { CONTACT_EMAIL } from "@/store/globalConfig/config";

export const zh_CN = {
  9999: "操作失败",
  10000: "请求参数无效，请检查参数后再重新请求",
  10001: "令牌无效，请检查后重试",
  10002: "令牌为空，请检查后重试",
  10003: "令牌已过期，请检查后重试",
  10004: "令牌失效，请联系管理员寻求帮助",
  10100: "无法上传图片，请联系管理员寻求帮助",
  10101: "不支持图像格式，目前只支持以下格式： JPG、JPEG、PNG",
  10102: "无法查询渲染进度，请联系管理员寻求帮助",
  10103: "渲染任务不存在",
  10104: "渲染图像选择无效",
  10200: "注册失败，电子邮件已被他人使用",
  10201: "无法创建用户，请联系管理员寻求帮助",
  10202: "电子邮件不存在，请联系管理员寻求帮助",
  10203: "用户名或密码不正确，请检查后重试",
  10204: "登录失败，请联系管理员寻求帮助",
  10205: "无法更新密码，请联系管理员寻求帮助",
  10206: "用户不存在",
  10207: "电子邮件格式无效，请检查后重试",
  10208: "无法发送电子邮件，请联系管理员寻求帮助",
  10209: "无法激活电子邮件，请联系管理员寻求帮助",
  10211: "token已过期，email激活失败",
  10212: "无法通过 facebook 帐户登录，请联系管理员寻求帮助",
  10213: "无法通过谷歌账户登录，请联系管理员寻求帮助",
  10214: "无法执行 Oauth 请求，请联系管理员寻求帮助",
  10215: "无法获取双因素验证码，请联系管理员寻求帮助",
  10216: "双因素验证失败，请在检查后重试",
  10217: "双因素验证已禁用",
  10218: "电子邮件已激活",
  10219: "电子邮件激活令牌无效，请联系管理员寻求帮助",
  10220: "无法渲染图像，订购余额不足",
  10221: "Recaptcha 验证失败，请检查后重试",
  10222: "无法显示图像，订阅已过期，请联系管理员寻求帮助",
  10223: "无法重置密码，请联系管理员寻求帮助",
  10224: "邮箱未激活，请在激活后重试",
  10225: "登录数据异常，请联系管理员寻求帮助",
  10226: "用户名太长，长度不能超过 50",
  10227: "用户注销失败",
  10228: "无法登录 Apple 帐户，请联系管理员寻求帮助",
  10229: "删除用户失败",
  10230: "用户未激活，请联系管理员寻求帮助",
  10231: "第三方登录后，账户需要修改密码",
  10234: "该用户类型不是代理人类型",
  10236: "您的登录受限。请在 15 分钟后重试",
  10300: "试用次数已用完,请登录",
  10400: "无法创建订阅，请联系开发人员寻求帮助",
  10401: "无法创建付款方式，请联系开发人员寻求帮助",
  10402: "无法删除付款方式，请联系开发人员寻求帮助",
  10500: "渲染图像失败，订阅为空，请订阅并使用",
  10501: "订阅失败，当前正在使用的订阅已经存在",
  10502: "订阅失败，订阅正在进行中",
  10503: "订阅状态异常，请联系管理员寻求帮助",
  10504: "当前平台不支持取消订阅，请联系管理员寻求帮助",
  10505: "当前平台不支持恢复订阅，请联系管理员寻求帮助",
  10600: "2fa 未验证，请验证",
  10700: "注意模板 ID 为空，请联系管理员寻求帮助",

  10802: "受邀请人不能为当前帐号, 请检查后重新尝试",
  10804: "邀请链接无效或者不存在, 请检查后重新尝试",
  10805: "邀请链接无效，当前账户的电子邮件与受邀电子邮件不匹配",
  10806: "该账户已与邀请者账户绑定",
  10808: "邀请者已达到最大绑定限制，请联系邀请者",
  10810: "往来账户是代理账户，不能与其他代理账户关联",

  11000: "平台无效，请联系管理员寻求帮助",
  11001: "渲染历史不存在，请联系管理员寻求帮助",
  HOME_PAGE: "首页",
  RENDER: "渲染",
  SUBSCRIBE: "订阅",
  SETTINGS: "设置",
  USER: "用户",
  USER_INFO: "用户信息",
  QUIT: "退出",
  AID_MAKES_AI_CHANGE_LIFE: "KainoAI让AI改变生活",
  YOU_CAN_UPLOAD_ROOM_PHOTOS:
    "您可以上传房间照片并设置渲染参数，KainoAI会为您生成理想的房间",
  GENERATE_MY_DREAM_ROOM: "生成我的梦想房间",
  LATEST_RENDERS: "渲染",
  DROP_AN_IMAGE: "拖放图片/点击选择",
  KEYWORDS: "Keywords",
  ROOM: "房间",
  MODE: "模式",
  STYLE: "风格",
  RESOLUTION: "分辨率",
  HISTORY: "历史",
  ONLY_JPG_PNG: "只能上传 JPG/PNG 格式的图片。",
  TASK_EXECUTION_FAILURE: "任务执行失败。",
  RENDERING: "渲染中...",
  UUID_INVALID: "uuid 无效",
  TOKEN: "Token",
  TOTAL: "总量",
  TOKEN_USAGE_RECORDS: "Token使用记录",
  CREATEDATETIME: "创建时间",
  USERID: "用户ID",
  // RENDERING: "渲染图",
  IMAGE_GENERATION_RECORD: "图片生成记录",
  SUCCESSFULLY_MODIFIED: "修改成功",
  SUCCESSFULLY_SET: "设置成功",
  VERIFICATION_SUCCESSFUL: "验证成功",
  ACCOUNT_MANAGEMENT: "账号管理",
  CHANGE_PASSWORD: "修改密码",
  OLD_PASSWORD: "旧密码",
  NEW_PASSWORD: "新密码",
  CONFIRM_PASSWORD: "确认密码",
  YOUR_NEW_PASSWORD: "您的新密码",
  APPLY: "应用",
  PLEASE_INPUT_YOUR_OLD_PASSWORD: "请输入您的旧密码",
  PLEASE_INPUT_YOUR_NEW_PASSWORD: "请输入您的新密码",
  THE_PASSWORD_MUST_BE_AT_LEAST_8: "密码长度必须至少为6个字符",
  PLEASE_CONFIRM_YOUR_NEW_PASSWORD: "请确认您的新密码",
  THE_TWO_PASSWORDS_DO_NOT_MATCH: "您输入的两次密码不匹配",
  SECURITY_SETTINGS: "安全设置",
  WHETHER_TO_ENABLE_TWO_FACTOR_AUTHENTICATION: "是否开启二次验证:",
  SCAN_THE_QR_CODE_BELOW_TO_BIND: "扫描下方二维码绑定",
  CHECK: "校验",
  SYSTEM_LANGUAGE_SETTING: "系统语言设置",
  LANGUAGE: "语言",
  SIMPLIFIED_CHINESE: "简体中文",
  ENGLISH: "英文",
  TRADITIONAL_CHINESE: "繁体中文",
  UPLOADING_SUCCESSFULLY: "上传成功",
  UPLOAD_FAILED: "上传失败",
  PLEASE_UPLOAD_THE_IMAGE: "请上传图片",
  E_MAIL: "E-mail",
  THE_INPUT_IS_NOT_VALID_E_MAIL: "输入的电子邮件无效",
  PLEASE_INPUT_YOUR_E_MAIL: "请输入您的电子邮箱",
  PLEASE_INPUT_YOUR_PASSWORD: "请输入您的密码",
  LOGIN: "登录",
  DO_NOT_HAVE_AN_ACCOUNT_YET: "还没有帐户？",
  SIGN_UP_HERE: "在此注册",
  FOR_MORE_INFORMATION_ON_GREYPANE_ACCOUNTS: "有关 AI 帐户的更多信息，",
  CLICK_HERE: "点击这里。",
  ACCOUNT_LOGIN: "帐号登录",
  PASSWORD: "密码",
  SIGN_UP: "注册",
  PLEASE_CONFIRM_YOUR_PASSWORD: "请确认您的密码",
  YOUR_PASSWORD: "你的密码",
  ALREADY_AN_AID_ACCOUNT_HOLDER: "已经是 KainoAI 账户持有人？",
  LOGIN_HERE: "在此登录。",
  EMAIL: "邮箱",
  WELCOME_TO_LOG_IN_TO_YOUR_GREYPANEL_ACCOUNT: "欢迎登录您的Stream账户",
  LOGIN_SUCCESSFUL: "登录成功",
  DUAL_AUTHENTICATION: "双重认证 (2FA)",
  A_MESSAGE_CONTAINING: "包含验证码的消息已发送到您的 Google 身份验证器",
  PLEASE_ENTER_THE_6_DIGIT_TWO_FACTOR: "请输入6位安全码以继续",
  CONTINUE: "继续",
  CANCEL: "取消",
  DOCUMENT_VERIFICATION_SUCCESSFUL: "文件验证成功",
  DEAR_USER: "亲爱的用户",
  YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED: "恭喜，您的账户已成功验证",
  CLICK_HERE_TO_LOGIN: "点击此处登录",
  VERIFICATION_FAILED: "验证失败",
  DOCUMENT_VALIDATION_FAILED: "文件验证失败",
  VERIFICATION_HAS_EXPIRED:
    "出现了一些问题，该验证已过期。我们已重新发送激活邮件到您的邮箱，请注意查收。",
  MAIL_SENT_SUCCESSFULLY: "邮件发送成功",
  EMAIL_SENDING_FAILED: "邮件发送失败",
  AUTOMATICALLY_JUMP_AFTER_3_SECONDS:
    "3秒后自动跳转到首页，或者点击按钮立即跳转",
  AUTOMATICALLY_JUMP_AFTER_S: "s后自动跳转",
  JUMP_NOW: "立即跳转",
  
  USE_A_THIRD_PARTY_LOGIN: "使用第三方登录",
  LOG_IN_WITH_FACEBOOK: "使用Facebook登录",
  LOG_IN_WITH_GOOGLE: "使用Google登录",
  LOG_IN_WITH_APPLE: "使用Apple登录",
  OPERATION_VALUE: "操作值",
  PREFERENCE: "偏好设置",
  AUTHENTICATION: "验证",
  LOGIN_HISTORY: "登录历史",
  LAST_LOGIN_TIME: "最近登录时间",
  GOOGLE_AUTH_DISABLED: "已关闭谷歌二次验证",
  GOOGLE_AUTH_ENABLED: "已开启谷歌二次验证",
  
  LOG_OUT: "退出登录",
  DELETE: "删除",
  ARE_YOU_SURE_YOU_WANT_TO_DELETE_THIS_GENERATED_RECORD:
    "你确定要删除此条生成记录吗？",
  BILLING_CYCLE: "结算周期",
  USED: "已使用",
  STARTING_TIME: "起始时间",
  REGISTRATION_TIME: "登记时间",
  TWO_FACTOR_AUTHENTICATION: "双重认证",
  ENABLE_TWO_FACTOR_AUTHENTICATION_ON_YOUR_PHONE:
    "*使用一次性密码验证器(2FA)在您的手机或电脑上启用双重认证",
  PARAMETER: "参数",
  ACTION: "操作",
  VIEW: "预览",
  BROWSER: "浏览器",
  ACCESSON: "访问时间",
  LOGIN_IP: "登录IP",
  CREATE_NEW_PASSWORD: "创建新密码",
  PASSWORD_RESET_SUCCESSFUL: "密码重置成功",
  YOUR_NEW_PASSWORD_MUST_BE_DIFFERENT_FORM_PREVIOUS_USED_PASSWORDS:
    "您的新密码必须与以前使用的密码不同。",
  RESET_PASSWORD_EMAIL_SENT_SUCCESSFULLY: "重置密码邮件发送成功",
  RESET_PASSWORD: "重设密码",
  ENTER_THE_EMAIL_ASSOCIATED_WITH_YOUR_ACCOUNT:
    "输入与您的帐户关联的电子邮件，我们将发送一封电子邮件，其中包含重置密码的说明。",
  ONE_TIME_PAYMENT: "一次性付",
  MONTHLY_PAYMENT: "月付",
  QUARTERLY_PAYMENT: "季付",
  ANNUAL_PAYMENT: "年付",
  FLOOR: "地板",
  WALL: "墙壁",
  CEILING: "天花板",
  YOUR_EMAIL_ADDRESS_IS_INCOMPLETE: "您的邮件地址不完整",
  YOUR_BANK_CARD_NUMBER_IS_INCOMPLETE: "您的银行卡卡号不完整",
  THE_EXPIRY_DATE_OF_YOUR_CARD_IS_INCOMPLETE: "您的银行卡的到期日不完整",
  THE_SECURITY_CODE_OF_YOUR_BANK_CARD_IS_INCOMPLETE: "您的银行卡的安全码不完整",
  YOUR_CARD_NAME_IS_INCOMPLETE: "您的银行卡姓名不完整",
  SUBSCRIPTION_TYPE: "订阅类型",
  SUBSCRIPTION_STATUS: "订阅状态",
  SUBSCRIPTION_EXPIRATION_TIME: "订阅到期时间",
  SUBSCRIPTION_START_TIME: "订阅开始时间",
  AID_MAKES: "KainoAI 让",
  AI_CHANGE_LIFE: "Ai改变生活",
  RENDERS: "渲染图",
  UPGRADE_TO_PLUS: "升级到Plus",
  WANT_UNLIMITED_USES: "想要无限使用?",
  QUICKLY: "快速",
  TESTER: "免费试用",
  YOU_HAVE_3_FREE_TEST_OPPORTUNITIES: "您有3次免费试用机会",
  PLUS: "Plus",
  UPGRADE_TO_PLUS_UNLIMITED_USE: "升级到Plus，无限制使用",
  FOR_MORE_QUESTIONS_PLEASE_CONTACT: "如有更多问题，请联系",
  SELECT_IMAGE: "选择图片",
  WELCOME_BACK_PLEASE_LOGIN_TO_YOUR_ACCOUNT: "欢迎回来!请登录您的帐户。",
  REMEMBER_ME: "记住我",
  FORGOT_PASSWORD: "忘记密码",
  PLEASE_SIGN_UP_YOUR_ACCOUNT: "请注册您的帐户",
  ALREADY_A_AI_ACCOUNT_HOLDER: "已经是AI账户持有人?",
  REGISTRATION_SUCCESSFUL: "注册成功！",
  THANK_YOU_WE_HAVE_SENT_YOU_EMAIL_TO: "谢谢。 我们已向您发送电子邮件",
  PLEASE_CLICK_ON_THE_LINK_IN_THAT_EMAIL_TO_ACTIVATE_YOUR_ACCOUNT:
    "请点击该电子邮件中的链接来激活您的帐户。",
  IF_YOU_DID_NOT_RECEIVE_THE_EMAIL_PLEASE_CLICK_THE_BUTTON_BELOW_TO_RESEND:
    "如果您没有收到电子邮件，请点击下面的按钮重新发送。",
  RESEND: "重发",
  NEXT: "下一步",
  SUBSCRIBE_TO_AI_PLUS: "订阅AI Plus",
  PER: "每",
  MONTH: "月",
  AI_PLUS_SUBSCRIPTION: "AI Plus订阅",
  BILLED_MONTHLY: "按月计费",
  SUBTOTAL: "小计",
  TAX: "税",
  TOTAL_DUE_TODAY: "今天截止",
  THE_NUMBER_OF_FREE_TRIALS_HAS_BEEN_USED_UP:
    "免费试用次数已用完,您可以选择登录后继续生成或者回到首页等待免费试用次数刷新",
  GO_HOME: "回到首页",
  IMAGE_ADDRESS_HAS_BEEN_COPIED: "图片地址已复制，分享给好友吧",
  RENDERS_IMAGE: "渲染图像",
  ORIGINAL_IMAGE: "原始图像",
  SHARE_WITH: "分享",
  COPY: "复制",
  CONGRATULATIONS_YOUR_ACCOUNT_HAS_BEEN_SUCCESSFULLY_VERIFIED:
    "祝贺您，您的帐户已成功验证",
  AUTOMATICALLY_JUMP_TO_THE_HOME_PAGE_AFTER_3_SECONDS_OR_CLICK_THE_BUTTON_TO_JUMP_IMMEDIATELY:
    "自动跳转到首页后3秒，或单击按钮立即跳转",
  AUTOMATIC_JUMP: "自动跳转",
  DOCUMENT_FAILED: "Document Failed",
  SOME_PROBLEMS_HAVE_OCCURRED:
    "出现了一些问题，验证已经验证已经过期。我们已经将激活邮件重新发送到您的邮箱，请查收。",
  BACK: "返回",
  ORDER_PAID_SUCCESSFULLY: "订单支付成功",
  CONGRATULATIONS_FOR_BECOMING_A_PLUS_USER: "祝贺你成为Plus用户",
  CONGRATULATIONS_ON_OBTAINING_THE_FOLLOWING_RIGHTS: "祝贺您获得以下权利",
  WE_WILL_EMAIL_YOU_AN_ORDER_CONFIRMATION_WITH_DETAILS_INFO:
    "我们将通过电子邮件向您确认订单的详细信息。",
  IF_YOU_HAVE_QUESTIONS_ABOUT_YOUR_ORDER_YOU_CAN_EMAIL_US_AT:
    "如果您对您的订单有疑问，您可以给我们发电子邮件",
  VIEW_NOW: "现在查看",
  BILL: "账单",
  MY_BANK: "我的银行",
  RESUME_PLUS: "恢复Plus",
  CANCEL_PLUS: "取消Plus",
  ARE_YOU_SURE_YOU_WANT_TO_CANCEL_PLUS_YOU_WILL_LOSE:
    "您确定要取消Plus吗，您将失去plus权益",
  SUMBIT: "Submit",
  CONFIRM_RESUME: "确认恢复",
  ARE_YOU_SURE_YOU_WANT_TO_RESUME_THE_PLUS_IT_WILL_START_BILLING_AGAIN_ON:
    "您确定要恢复 Plus 吗？ 它将再次开始计费",
  CONFIRM: "确认",
  NOTICE: "注意",
  PLEASE_INSTALL_A_SOFTWARE_TOKEN_INSTALLER_FROM_YOUR_APPLICATION_LIBRARY:
    "请从您的应用程序库安装软件令牌安装程序，例如Google Authenticator，然后扫描此二维码，更多信息可以在用户手册中找到",
  CAN_NOT_SCAN_THE_QR_CODE: "无法扫描二维码？",
  TO_ADD_AN_ENTRY_MANUALLY: "要手动添加条目，请在移动应用程序中提供以下信息",
  SECRET_KEY: "密钥",
  AUTH_METHOD: "认证方式",
  TIME_BASED: "基于时间",
  REGISTER_WITH_THE_DUAL_AUTHENTICATION_APPLICATION: "注册双重认证应用程序",
  PIN_CODE: "PIN码",
  PROFILE_PHOTO: "头像照片",
  USER_NAME: "用户名",
  LOGOUT: "退出",
  PASSWORD_MUST_BE_8_16_CHARACTERS_AND_CONTAIN_BOTH_NUMBERS_AND_LETTERS_SPECIAL_CHARACTERS:
    "密码必须为8-16个字符，并且包含数字和字母/特殊字符",
  SAVE: "保存",
  ARE_YOU_SURE_YOU_WANT_TO_LOG_OUT: "您确定要退出登录吗",
  MY_RENDER: "我的渲染",
  MORE: "更多",
  ORIGINAL: "原始图",
  SUCCESSFULLY_DELETED: "删除成功",
  REMOVE_CARD: "移除卡",
  ADD_CARD: "添加卡",
  ARE_YOU_SURE_REMOVE_CARD: "你确定要移除卡片吗",
  PAYMENT: "已付款",
  PENDING: "等待",
  PAYMENT_FAILED: "付款失败",
  WELCOME_TO_LOG_IN_TO_YOUR_AI_ACCOUNT: "欢迎您登录您的AI账号",
  DUAL_AUTHENTICATION_2FA: "双重身份验证 (2FA)",
  A_MESSAGE_CONTAINING_A_VERIFICATION_CODE_HAS_BEEN_SENT_TO_YOUR_GOOGLE_AUTHENTICATOR:
    "一条包含验证码的消息已发送至您的 Google 身份验证器。请输入 6 位双因素安全码以继续",
  BY_PROVIDING_YOUR_CARD_DETAILS:
    "通过提供您的卡详细信息，您允许 KainoAI 根据其条款向您的卡收取未来付款的费用",
  SECURELY_SAVE_MY_INFORMATION_FOR_1_CLICK_CHECKOUT:
    "安全保存我的信息以便一键结账",
  ENTER_YOUR_PHONE_NUMBER_TO_CREATE_A_LINK_ACCOUNT:
    "输入您的电话号码以创建 Link 帐户，并在 KainoAI 上以及任何接受 Link 的地方更快地付款。",
  ENTER_ADDRESS_MANUALLY: "手动输入地址",
  I_AM_PURCHASING_AS_A_BUSINESS: "作为企业购买",
  YOU_WILL_BE_CHARGED_THE_AMOUNT_LISTED_ABOVE_EVERY_MONTH_UNTIL_YOU_CANCEL:
    "在您取消之前，我们将每月向您收取上面列出的金额。我们可能会按照使用条款中的规定调整价格。 您可以随时取消。 订阅即表示您同意 KainoAI 的使用条款和隐私政策",
  REGISTER: "登记",
  TERM_OF_USE_PRIVACY_POLICY: "使用期限，隐私政策",
  SOURCE_IMAGE: "源图像",
  GENERATED_IMAGE: "生成的图像",
  CREATION_TIME: "创建时间",
  RESEND_INSTRUCTIONS: "重新发送",
  PLAN: "计划",
  ADD_A_CREDIT_DEBIT_CARD: "添加信用卡/借记卡",
  YOU_HAVE: "每天有 ",
  FREE_TEST_OPPORTUNITIES: " 次免费试用机会",
  RESOLUTION_IS_REQUIRED: "分辨率是必填项",
  ACCOUNT: "账户",
  EMAIL_LENGTH_CANNOT_EXCEED_50_CHARACTERS: "邮件长度不能超过50个字符!",
  NUMBER_OF_RENDERING_TIMES_AVAILABLE: "可使用渲染次数",
  TIME_DUE: "到期时间",
  RENDERING_FAILED_FREE_TIMES_HAVE_BEEN_EXHAUSTED:
    "渲染失败，免费次数已用尽，请联系管理员",
  MAKE_THE_DESIGN_EASIER: "让设计变得更简单",
  UPLOAD_SPACE_PHOTO_CONFIGURE_RENDERING_PARAMETERS:
    "上传空间照片，配置渲染参数，生成你的专属设计",
  GET_YOUR_DESIGN: "开始",
  FREE_TESTER: "免费试用",
  REGISTER_ACCOUNT: "注册账户",
  CLAIM_100_PROFESSIONAL_RENDERING_CREDITS_FOR_FREE:
    "免费获取100次专业级渲染额度!",
  DO_NOT_SELECT: "不选择",
  VIRTUAL_STAGING: "虚拟层次",
  INTERIOR_DESIGN: "室内设计",
  FREESTYLE: "自由式",
  LIVING_ROOM: "客厅",
  BEDROOM: "卧室",
  BATH_ROOM: "浴室",
  ATTIC: "阁楼",
  KITCHEN: "厨房",
  DINING_ROOM: "餐厅",
  STUDY_ROOM: "学习室",
  HOME_OFFICE: "家庭办公室",
  GAMING_ROOM: "游戏厅",
  HOUSE_EXTERIOR: "房屋外观",
  OUTDOOR_POOL_AREA: "室外游泳池",
  OUTDOOR_PATIO: "室外露台",
  OUTDOOR_GARDEN: "室外庭院",
  MEETING_ROOM: "会议室",
  WORKSHOP: "工作室",
  FITNESS_GYM: "健身房",
  COFFEE_SHOP: "咖啡厅",
  CLOTHING_STORE: "服装店",
  WALK_IN_CLOSET: "壁橱",
  TOILET: "洗手间",
  RESTAURANT: "餐厅",
  OFFICE: "办公室",
  COWORKING_SPACE: "办公空间",
  HOTEL_LOBBY: "酒店大厅",
  HOTEL_ROOM: "酒店客房",
  HOTEL_BATHROOM: "酒店浴室",
  EXHIBITION_SPACE: "展览空间",
  ONSEN: "温泉",
  MUDROOM: "储物室",
  DROP_ZONE: "闲置区",
  EASTER: "复活节",
  MODERN: "现代",
  MINIMALIST: "极简",
  CONTEMPORARY: "当代",
  SCANDINAVIAN: "北欧",
  INTERIOR_AI: "室内设计",
  ZEN: "禅",
  MIDCENTURY_MODERN: "中世纪现代",
  TROPICAL: "热带",
  BIOPHILIC: "亲自然",
  INDUSTRIAL: "工业",
  ART_DECO: "装饰艺术",
  FARMHOUSE: "农舍",
  JAPANESE_DESIGN: "日式设计",
  RUSTIC: "乡村",
  BOHEMIAN: "波希米亚",
  COASTAL: "海岸",
  VINTAGE: "复古",
  COTTAGECORE: "别墅",
  FRENCH_COUNTRY: "法式乡村",
  ART_NOUVEAU: "新艺术",
  CYBERPUNK: "赛博朋克",
  MAXIMALIST: "极简",
  BAROQUE: "巴洛克",
  VAPORWAVE: "蒸汽波",
  SKI_CHALET: "滑雪小屋",
  CHRISTMAS: "圣诞节",
  SKETCH: "写生",
  TRIBAL: "部落",
  MEDIEVAL: "中世纪",
  CHINESE_NEW_YEAR: "中国新年",
  HALLOWEEN: "万圣节",
  NEOCLASSIC_PRO: "新古典主义",
  AVAILABLE_TOKEN: "可用令牌",
  PREVIEW: "预览",
  STYLE_IS_REQUIRED: "风格是必填项",
  ROOM_IS_REQUIRED: "房间是必填项",
  MODE_IS_REQUIRED: "模式是必填项",
  SPARE: "剩余",
  FREQUENCY: "次",

  RE_RENDER: "重新渲染",
  DOWNLOAD_RENDERING: "下载渲染图",
  VERSIONS: "版本",
  CANCEL_RENDERINH: "取消渲染",
  SELECT: "请选择",
  TIP: "提示",
  CANCEL_RENDERING_TIP_TEXT:
    "正在渲染中。如果您选择取消，当前渲染次数将不会退还，仍将被扣除。你确定取消吗?",
  LOGIN_FAILED_ERROR_MESSAGE: "登录失败错误提示：",
  GENERATE: "渲染图",
  RENDER_FAILURE_TIP: "渲染失败提示：",
  INSUFFICIENT_NUMBER_OF_RENDERINGS: "渲染次数不足",
  COPY_IMAGE_LINK: "复制图片链接",
  SHARED_LINK: "共享链接",
  RESIDENTIAL: "住宅",
  COMMERCIAL: "商业",
  DESGIN: "设计",
  TODAY: "今天",
  YESTERDAY: "昨天",
  RECENT_7DAYS: "前7天",
  RECENT_30DAYS: "前30天",
  EARLIER: "更早之前",
  WE_ARE_COMMITTED_TO_YOUR_PRIVACY:
    "我们致力于保护您的隐私。KainoAI使用您提供的信息与您联系，向您提供我们相关的内容、产品和服务信息。您可以随时取消订阅这些通讯。更多信息，请查阅我们的",
  PRIVACY_POLICY: "隐私政策",
  UPLOAD_PICTURE: "上传图片",
  CHOOSE_THE_STYLE: "选择风格",
  CHOOSE_THE_ROOM: "选择房间",
  CHOOSE_THE_MODE: "选择模式",
  CHOOSE_THE_RESOLUTION: "选择分辨率",
  PLEASE_WAIT_GENERATING_THE_DESIGN: "请稍等，正在生成设计...",
  DETAILS: "详情",
  DOWNLOAD: "下载",
  RESTART: "重新渲染",
  SHARE: "分享",
  FULL: "全视图",
  AVAILABLE_CREDIT: "可使用渲染次数",
  ARE_YOU_SURE_YOU_WANT_TO_RENDER_IT_AGAIN: "您确定再次渲染吗？",
  PROFILE: "个人主页",
  RENDER_HISTORY: "渲染历史",
  GENERATES: "生成",
  WAIVE: "放弃",
  RENDER_AGAIN: "再次渲染",

  MEMBERS: "成员",
  ACTIVITIES: "活动",
  INVITE_YOUR_FRIENDS: "邀请您的朋友",
  CREDIT: "次数",

  DATA_PRIVACY_MARKETING_POLICYKAINOAI: "数据隐私和营销政策 - KainoAI",
  WE_ARE_COMMITTED_TO_ENSURING_THE_PRIVACY_AND_SECURITY_OF_YOUR_PERSONAL_INFORMATION:
    "我们致力于确保您个人信息的隐私和安全。本隐私政策概述了我们如何收集、使用和保护您的数据。通过使用我们的服务，您同意接受此处描述的条款和做法。",
  INFORMATION_WE_COLLECT: "1. 我们收集的信息",
  WE_MAY_COLLECT_THE_FOLLOWING_TYPES_OF_INFORMATION:
    "我们可能收集以下类型的信息：",
  PERSONAL_INFORMATION_NAME_EMAIL_ADDRESS_CONTACT_DETAILS:
    "个人信息：姓名、电子邮件地址、联系方式。",
  DESIGN_PREFERENCES_INFORMATION_YOU_PROVIDE_REGARDING_YOUR_DESIGN_NEEDS_AND_PREFERENCES:
    "设计偏好：您提供的有关设计需求和偏好的信息。",
  USAGE_DATA_INTERACTION_DATA_SUCH_AS_YOUR_INTERACTIONS_WITH_OUR_WEBSITE_AND_DESIGN_TOOLS:
    "使用数据：交互数据，例如您与我们的网站和设计工具的互动。",
  WE_COLLECT_THIS_INFORMATION_TO_DELIVER_PERSONALIZED_AND_EFFICIENT_GRAPHIC_DESIGN_SOLUTIONS_TO_YOU:
    "我们收集此信息以向您提供个性化和高效的图形设计解决方案。",
  HOW_WE_USE_YOUR_INFORMATION: "2. 我们如何使用您的信息",
  WE_USE_YOUR_INFORMATION_TO: "我们使用您的信息来：",
  CREATE_AND_DELIVER_GRAPHIC_DESIGNS_BASED_ON_YOUR_PREFERENCES:
    "基于您的偏好创建和交付图形设计。",
  COMMUNICATE_WITH_YOU_ABOUT_YOUR_DESIGN_PROJECTS_AND_UPDATES:
    "与您沟通有关您的设计项目和更新的信息。",
  IMPROVE_OUR_SERVICES_AND_OFFERINGS_BASED_ON_USER_INTERACTIONS:
    "使用数据：交互数据，例如您与我们的网站和设计工具的互动。",
  DATA_SECURITY: "3. 数据安全性",
  WE_TAKE_THE_SECURITY_OF_YOUR_DATA_SERIOUSLY:
    "我们认真对待您数据的安全性。我们采取技术和组织措施，以防止您的信息遭到未经授权的访问、丢失、滥用或更改。然而，请注意，通过互联网传输数据的任何方法都不是完全安全的。",
  SHARING_OF_INFORMATION: "4. 信息共享",
  WE_DO_NOT_SHARE_YOUR_PERSONAL_INFORMATION_WITH_THIRD_PARTIES_FOR_MARKETING_PURPOSES:
    "我们不会为营销目的与第三方分享您的个人信息。但是，我们可能与以下方分享您的信息：",
  OUR_SERVICE_PROVIDERS_WHO_ASSIST_US_IN_DELIVERING_OUR_DESIGN_SERVICES:
    "协助我们提供设计服务的服务提供商。",
  LEGAL_AND_REGULATORY_AUTHORITIES: "根据法律要求的法律和监管当局。",
  COOKIES_AND_TRACKING_TECHNOLOGIES: "5. Cookie和跟踪技术",
  OUR_WEBSITE_AND_SERVICES_MAY_USE_COOKIES:
    "我们的网站和服务可能使用Cookie和类似的跟踪技术来增强您的浏览体验并收集使用信息。您可以调整浏览器设置以管理Cookie偏好。",
  YOUR_CHOICES: "6. 您的选择",
  YOU_HAVE_THE_RIGHT_TO: "您有以下权利：",
  ACCESS_CORRECT_OR_DELETE_YOUR_PERSONAL_INFORMATION:
    "访问、更正或删除您的个人信息。",
  OPT_OUT_OF_RECEIVING_MARKETING_COMMUNICATIONS: "选择不接收营销通信。",
  RAISE_OBJECTIONS_OR_RESTRICT_THE_PROCESSING_OF_YOUR_DATA:
    "提出异议或限制数据处理。",
  TO_EXERCISE_THESE_RIGHTS_PLEASE_CONTACT_US_AT: `要行使这些权利，请通过${CONTACT_EMAIL}与我们联系。`,
  CHILDREN_PRIVACY: "7. 儿童隐私",
  OUR_SERVICES_ARE_NOT_INTENDED_FOR_INDIVIDUALS_UNDER_THE_AGE_OF:
    "我们的服务不面向[12]岁以下的个人。我们不会故意收集儿童的个人信息。",
  CHANGES_TO_PRIVACY_POLICY: "8. 隐私政策的更改",
  WE_MAY_UPDATE_THIS_PRIVACY_POLICY_PERIODICALLY_TO_REFLECT_CHANGES_IN_OUR_PRACTICES_AND_SERVICES:
    "我们可能定期更新本隐私政策，以反映我们的做法和服务的变化。修订版本将与生效日期一起发布在我们的网站上。",
  FOR_ANY_INQUIRIES_ABOUT_OUR_PRIVACY_POLICY_OR_DATA_PRACTICES: `有关我们的隐私政策或数据实践的任何查询，请通过${CONTACT_EMAIL}与我们联系。您的隐私对我们很重要，我们致力于解决您可能有的任何疑虑。`,
  KAINOAI_OPERATION_TEAM: "KainoAI运营团队",
  CONTACT_EMAIL: "联系电子邮件：",
  ISSUE: "发行",
  CREDITS: "关联",
  CHOOSE_CREDITS: "选择学分",
  INVITE_MEMBER: "邀请成员",
  TOP_UP: "充值",
  YOUR_AVAILABLE_CREDITS: "您的可用积分",
  ASSOCIATION: "协会",
  DISASSOCIATE: "解除关联",
  MULTIPLES_OF_100_CAN_BE_ALLOCATED_EACH_TIME: "每次可分配 100 的倍数",
  INVITATIONS: "邀请",
  PLEASE_ENTER_INVITE_EMAIL: "请输入邀请人电子邮件",
  THE_INPUT_IS_NOT_VALID_EMAIL: "输入的电子邮件无效！",
  PLEASE_INPUT_YOUR_EMAIL: "请输入您的电子邮件！",
  COPY_LINK: "复制链接",
  YOUR_REFERRAL_LINK: "您的推荐链接",
  DONE: "完成",
  AVAILABLE_CREDITS: "可用积分",
  SPENT: "已用",
  ALL: "全部",
  SUCCESSFUL_DISTRIBUTION: "分配成功",
  ASSOCIATED_MEMBER: "相关成员",
  RECEVED_CREDITS: "收到的积分",
  INVITATION_TO_JOIN: "邀请加入",
  CONSENT_CONFIRMED: "确定同意",
  OF_ESTABLISHING_AN_ASSOCIATED_APPLICATION: "的建立关联申请吗？",
  WHO_CAN_ASSIGN_CREDITS_TO_YOU:
    "同意后将与对方组织建立关联，对方可以为你分配积分",
  CONFIRM_DELETING_THIS_RENDERING_RECORD: "确认删除这一条渲染记录吗？",
  INITIALISATION_GIFT: "初始化赠送",
  THE_NUMBER_OF_TIMES_THAT_CAN_BE_ALLOCATED_CANNOT_EXCEED: "可分配次数不可超过",
  BINDING_FAILED: "绑定失败",
  DOES_IT_JUMP_TO_THE_ANDROID_BUNDLE: "是否跳转到Android绑定?",
  WEB_BINDING: "Web端绑定",
  ANDROID_BINDING: "Android端绑定",

  TRIGGERED_TIME: "触发时间",
  SEARCH: "搜索",
  NAME: "用户名",
  SURPLUS_CREDIT: "分配次数",
  NUMBER_OF_NEW_ACCOUNTS: "新账户次数",
  SENDING:"发送",
  SYSTEM_MAINTENANCE_NOTICE:"系统维护通知",
  INDIVIDUAL_CENTRE:"个人中心",
  RESEND_VERIFICATION_EMAIL:"重新发送邮件",
  CREATED_SUCCESSFULLY:"创建成功",
  EDIT:"修改",
  STATUS:"状态",
  LAST_USED:"最后使用",
  PLEASE_RATE_YOUR_SATISFACTION_WITH_THE_RENDERING_BY_KAINOAI:"请评价您对 KainoAI 渲染服务的满意度。",
  VERY_UNSATISFIED:"非常不满意",
  UNSATISFIED:"不满意",
  NEUTRAL:"中性",
  SATISFIED:"满意",
  VERY_SATISFIED:"非常满意",

  UPLOAD:"上传",
  ERASER:"擦除",
  AUTO:"AI 选区",
  AUTO_SELECTING_AN_ITEM_BY_CLICKING_ON_IT:"通过点击让AI自动选区",
  MANUAL:"手动选区",
  MANUAL_SELECTION_OF_ITEMS_BY_PAINTING:"通过手动选区",
  MULTIPLE_SELECTION:"多点选区",
  AUTOMATIC_ITEM_SELECTION_THROUGH_MULTIPLE_MARKER_POINTS:"通过多个标记点让AI自动选区",
  CONFIRMATION_OF_CHOICE:"确认选择",
  START: "开始",
  CONTRAST:"对比",
  REVOCATION:"撤销",
  RECOVER:"恢复",
  BRUSH_SIZE:"画笔大小",
  DRAG_AND_DROP_THE_IMAGE_HERE_TO_UPLOAD:"将图片拖放到此处进行上传",
  PLEASE_WAIT:"请稍等",
  GENERATING_THE_DESIGN:"正在生成设计",
  PLEASE_WAIT_A_MOVEMENT:"请稍等片刻",
};
