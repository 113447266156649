import { FC, ReactNode } from "react";
import Navigation from "../Navigation";
interface Iprops {
    children?: ReactNode
}
const LayoutPlx: FC<Iprops> = (props) => {
    return (
        <section>
            <Navigation />
            {props.children}

        </section>
    )
}
export default LayoutPlx