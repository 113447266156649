import { en_US } from "./en_US";
import { zh_CN } from "./zh_CN";
import { zh_TW } from "./zh_TW";

export const resources = {
	"zh_TW": {
		translation: zh_TW
	},
	"en_US": {
		translation: en_US
	},
	"zh_CN": {
		translation: zh_CN
	}
}