import useSubscribe from "@/Hooks/useSubscribe";
import useVisible from "@/Hooks/useVisible";
import { useLocale } from '@/polyglotplug';
import { IConfirmInfo } from "@/store/interface";
import { EInfoType } from "@/store/messageService";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { Button, Modal, Row, Space } from "antd";
import { FC, useCallback, useState } from "react";

const Confirm: FC = () => {
  const [visible, switchVisible] = useVisible();
  const t = useLocale();

  const [config, setConfig] = useState<IConfirmInfo>({
    body: "",
    event: () => {},
  });

  const loadConfirm = useCallback(
    (zconfig: IConfirmInfo) => {
      setConfig(zconfig);
      setTimeout(switchVisible, 50);
    },
    [switchVisible]
  );

  const reset = useCallback(() => {
    setConfig({
      body: "",
      event: () => {},
    });
  }, []);

  useSubscribe(EInfoType.delete_confirm_normal, loadConfirm);

  return (
    <Modal
      width={config?.config?.width ? config?.config?.width : 600}
      wrapClassName="delete-box"
      title={config?.config?.title ? config?.config?.title : t("TIPS")}
      open={visible}
      footer={null}
      zIndex={99999}
      onCancel={switchVisible}
      // okText={t("CONFIRM")}
      // cancelText={t("CANCEL")}
      afterClose={reset}
      // onOk={() => {
      //   config.event();
      //   switchVisible();
      // }}
    >
      <Space>
        <ExclamationCircleOutlined style={{ color: "#f50", fontSize: 20 }} />
        <div>{config.body}</div>
      </Space>
      <Row style={{ marginTop: 15 }} justify={"end"}>
        <Space>
          <Button
            style={{ minWidth: 120 }}
            onClick={() => {
              switchVisible();
              reset();
            }}
          >
            {t(config?.config?.cancelText || "CANCEL")}
          </Button>
          <Button
            style={{ minWidth: 120 }}
            onClick={() => {
              config.event();
              switchVisible();
            }}
            type="primary"
          >
            {t("CONFIRM")}
          </Button>
        </Space>
      </Row>
    </Modal>
  );
};

export default Confirm;
