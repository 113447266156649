// LanguageContext.tsx
import { setLocale } from '@/polyglotplug';
import { ReactNode, createContext, useContext, useState } from 'react';
import { getLanguage } from '../../react-i18next/i18n';
import accountService from '../../store/account/service';
type LanguageContextType = {
  language: string;
  changeLanguage: (newLanguage: string) => void;
};

const LanguageContext = createContext<LanguageContextType | undefined>(undefined);

export const LanguageProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const [language, setLanguage] = useState(accountService.lanInfo$.value || getLanguage());
  const changeLanguage = (newLanguage: string) => {
    setLanguage(newLanguage);
    setLocale(newLanguage);
    localStorage.setItem("language",newLanguage);
  };

  return (
    <LanguageContext.Provider value={{ language, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
export const useLanguage = (): LanguageContextType => {
  const context = useContext(LanguageContext);
  if (context === undefined) {
    throw new Error('useLanguage must be used within a LanguageProvider');
  }
  return context;
};
