import { setLocale } from "@/polyglotplug";
import {
  BehaviorSubject,
  Subject,
  debounceTime,
  delay,
  of,
  switchMap,
} from "rxjs";
import { get } from "../../server/api";
import { ELogin, IAccountInfo, ITokenStr } from "./interface";
export const TOKEN_KEY = "token";
export const EMAIL = "email";
class Account {
  /**用户当前信息 */
  readonly info$ = new BehaviorSubject<IAccountInfo | null>(null);
  public lanInfo$ = new BehaviorSubject<string | null>(null);
  /**登录状态 */
  readonly login$: BehaviorSubject<ELogin>;
  /** token */
  readonly token$: BehaviorSubject<ITokenStr>;
  /** logout service */
  readonly logout$ = new Subject();
  constructor() {
    const token = localStorage.getItem(TOKEN_KEY) || "";
    this.login$ = new BehaviorSubject<ELogin>(
      !!token ? ELogin.UNSET : ELogin.LOGINOUT
    );
    this.token$ = new BehaviorSubject<ITokenStr>("");
    // 自动载入缓存中的有效token
    if (token) {
      this.token$.next(token);
    }
    this.token$.subscribe((token) => {
      if (token) {
        this.initBasic(token);
      } else {
        localStorage.removeItem(TOKEN_KEY);
      }
    });

    this.logout$
      .pipe(debounceTime(50))
      .pipe(
        switchMap(() => {
          return of(this.login$.value !== ELogin.LOGINOUT);
        })
      )
      .subscribe((res) => {
        if (res) {
          this.resetLoginState();
        }
      });
  }

  /**
   * 初始化登录信息
   * @step1 验证token有效
   * @step2 token存储
   */
  private initBasic = async (token: string) => {
    if (token) {
      let response: any = await get("/user/detail", {}, { token });
      if (response.code === 200) {
        const info = response.data;
        if (info) {
          setLocale(info.locale)
          // i18n.changeLanguage(info.locale);
          this.info$.next(info);
          this.lanInfo$.next(info.locale);
          localStorage.setItem("language",info.locale)
          this.login$.next(ELogin.LOGINSU);
        }
        // token验证有效
        localStorage.setItem(TOKEN_KEY, token);
      } else if (response.code === 10224) {
        // token验证有效
        localStorage.setItem(TOKEN_KEY, token);
      } else if (response.code === 10600) {
        localStorage.setItem(TOKEN_KEY, token);
      } else {
        this.logout();
      }
    }
  };

  async triggerGetDetail() {
    let response: any = await get("/user/detail");
    if (response.code === 200) {
      const info = response.data;
      // i18n.changeLanguage(info.locale);
      setLocale(info.locale)
      this.info$.next(info);
      this.lanInfo$.next(info.locale);
      this.login$.next(ELogin.LOGINSU);
      localStorage.setItem("language",info.locale)
    }
  }

  readonly resetLoginState = () => {
    this.info$.next(null);
    this.token$.next("");
    setTimeout(() => {
      this.login$.next(ELogin.LOGINOUT);
    }, 500);
  };

  /**
   * 退出登录
   * 清空并重置
   */
  readonly logout = () => {
    const subs = of("logout")
      .pipe(delay(30))
      .subscribe(() => {
        localStorage.removeItem(TOKEN_KEY);
        this.resetLoginState();
        subs.unsubscribe();
      });
  };
  /**
   * token失效，注销登录信息
   */
  readonly removeLoginInfo = () => {
    this.logout$.next(true);
  };

  setToken(token: string) {
    // 登录成功之后将数据设置到token就可以触发登录信息的更新
    this.token$.next(token);
  }
}

const accountService = new Account();
export default accountService;
