import React, { FC } from "react";

import { Modal } from "antd";

/**
 *
 * 这个文件暂时没用
 * 本来想封装手机端弹窗，不过不知道封装成什么样  暂时搁置
 */
const Confirm: FC = () => {
  return <Modal></Modal>;
};

export default Confirm;
