// import { IObjectRouter } from "";
import { RouteObject } from "react-router-dom";
import LayoutPlx from "../components/layoutPlx";
export type IObjectRouter = RouteObject & {
    layout?: boolean
  }
/**
 *
 * @param routers 原始的路由配置
 * @returns 过滤后的路由
 */
export const filterRoutersConfig = (
    routers: IObjectRouter[]
): IObjectRouter[] => {
    const _routers = [...routers];
    const newRouters: IObjectRouter[] = [];
    try {
        if (!Array.isArray(_routers)) {
            return [];
        }
        _routers.forEach((item: IObjectRouter) => {
            if (typeof item.layout === "boolean" && !item.layout) {
                newRouters.push({
                    ...item,
                });
            } else {
                newRouters.push({
                    ...item,
                    element: <LayoutPlx>{item.element}</LayoutPlx>,
                });
            }
        });
    } catch (error) {
        console.error(error);
    }
    return newRouters;
};
