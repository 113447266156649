import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { useCallback, useEffect, useState } from "react";

const useFingerprint = () => {
  const [fingerprint, setFingerprint] = useState<string>("");
  const getFingerprint = useCallback(async () => {
    // 获取存储的浏览器指纹
    const FINGER_PRINT = localStorage.getItem("FINGERPRINT");
    if (FINGER_PRINT) {
      setFingerprint(FINGER_PRINT);
    } else {
      // 创建FingerprintJS实例
      const fpPromise = await FingerprintJS.load();
      const visitor = await fpPromise.get();
      const visitorId = visitor.visitorId;
      if (visitorId !== "") {
        localStorage.setItem("FINGERPRINT", visitorId);
      }
      setFingerprint(visitorId);
    }
  }, []);

  useEffect(() => {
    getFingerprint();
  }, [getFingerprint]);
  return fingerprint;
};

export default useFingerprint;
