export interface IAccountInfo {
  id: number;
  userName: string;
  openId: string;
  emailActive: number;
  twoFactorAuth: number;
  locale: string;
  initSubscribe: number;
  lastLoginDateTime: string;
  createDateTime: string;
  source: number;
  isSubscribe: boolean;
  twoFactorAuthStatus: boolean;
  subscriptionPlatform: string;
  freeToken: number;
  passwordStatus: number;
  userType: number;
  isAssociated: number;
  apiSwitch:number;
}
export enum ELogin {
  /**初始状态 */
  UNSET = "usr-unSet",
  /** 登录有效 */
  LOGINSU = "usr-loginsu",
  /** 登录失效 */
  LOGINOUT = "usr-loginout",
}

/**token */
export type ITokenStr = string;
