import {useCallback, useMemo, useState, useEffect} from "react";
import {BehaviorSubject} from "rxjs";
import enJson from "./package/en";
import twJson from "./package/tw";
import zhJson from "./package/zh";

const type$ = new BehaviorSubject<string>("en_US");

// 设置语言
export const setLocale = (type: string) => {
    type$.next(type);
}

// 使用语言包
export const useLocale = () => {
    const [type, setType] = useState<string>(type$.value);

    // subject
    useEffect(() => {
        const subscription = type$.subscribe(setType);
        return () => {
            subscription.unsubscribe();
        };
    }, []);

    const localePackage: any = useMemo(() => {
        switch (type) {
            case "en_US":
                return enJson;
            case "zh_TW":
                return twJson;
            case "zh_CN":
                return zhJson;
            default:
                return enJson;
        }
    }, [type])

    return useCallback((msg: string, obj?: any) => {
        const temp = localePackage[msg] || msg;
        if(typeof obj !== "undefined") {
            return temp.replace(/\{(\w+)}/g, (match: any, key: string | number) => {
                return obj[key] || match;
            });
        }else{
            return temp;
        }
    }, [localePackage]);
}
