import React, { FC } from "react";
import Confirm from "./Confirm";
const PCMadelPlx: FC = () => {
  return (
    <React.Fragment>
      <Confirm />
    </React.Fragment>
  );
};

export default PCMadelPlx;
