import { Modal } from "antd";
import { Dispatch, FC, ReactNode, SetStateAction } from "react";
import './index.less';
interface IModalView {
    open:boolean,
    setOpen:Dispatch<SetStateAction<boolean>>
    width?:number | string
    children:ReactNode
    title?:any
}
const ModalView: FC<IModalView> = ({open,setOpen,width=802,children,title=""}) => {
    return (
        <div className="my-modal-view">
            <Modal title={title} open={open} onCancel={()=>setOpen(false)} width={width} footer={null} centered={true} destroyOnClose={true}>
                {children}
            </Modal>
        </div>
    )
}
export default ModalView;