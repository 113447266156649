import { useEffect } from "react";
import messageService, { EInfoType } from "../store/messageService";
export type ICallback<T = any> = (value: T) => void;

const useSubscribe = <T>(actionType: EInfoType, load: ICallback<T>) => {
  useEffect(() => {
    const sub = messageService
      .createSubscibe(actionType)
      .subscribe((action) => {
        const _value = action.value as T;
        load(_value);
      });
    return () => sub.unsubscribe();
  }, [load, actionType]);
};

export default useSubscribe;
