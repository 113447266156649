import { useEffect, useState } from "react";
import { IAccountInfo } from "../store/account/interface";
import accountService from "../store/account/service";

/**
 * Custom React Hook for monitoring the user's current login status.
 * @returns The current user account information or null if not logged in.
 */
const useUserDetail = (): IAccountInfo | null => {
  const [state, setState] = useState<IAccountInfo | null>(
    accountService.info$.value
  );
  useEffect(() => {
    const subscription = accountService.info$.subscribe((value) => {
      setState(value);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  return state;
};

export default useUserDetail;
