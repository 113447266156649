import useAnalyticsEventTracker from '@/Hooks/useAnalyticsEventTracker';
import useFingerprint from '@/Hooks/useFingerprint';
import useUserDetail from '@/Hooks/useUserDetail';
import { useLanguage } from '@/components/LanguageContext';
import { setLocale, useLocale } from '@/polyglotplug';
import { get } from '@/server/api';
import { CONTACT_EMAIL } from '@/store/globalConfig/config';
import { staticLanguage$ } from '@/store/language';
import { UserOutlined } from '@ant-design/icons';
import { Avatar, Badge, Button, Dropdown, Space, Tooltip } from 'antd';
import React, { FC, useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import logo from "../../../../img/header-logo.svg";
import { getLanguage } from '../../../../react-i18next/i18n';
import ModalView from '../ModalView';
import BusinessCard from './BusinessCard';
import './index.less';

type Language = "zh_CN" | "en_US" | "zh_TW"

const Navigation: React.FC = () => {
    const navigate = useNavigate();
    const userInfo = useUserDetail();
    const language = localStorage.getItem("language");
    const [locale, setLocales] = useState<string | undefined>(language || getLanguage());
    const t = useLocale();
    const [isDisableTester, setIsDisableTester] = useState<boolean>(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const gaEventTracker = useAnalyticsEventTracker('p_header');
    const [freeToken, setFreeToken] = useState<number>(0)
    const fingerprint = useFingerprint();

    const goHome = useCallback(
        () => {
            navigate(`/home`);
        },
        [navigate],
    )
    const goLogin = useCallback(
        () => {
            navigate(`/login`);
        },
        [navigate],
    )
    const languageR = useCallback(
        (str: any) => {
            if (str) {
                const data: any = {
                    zh_CN: "简体中文",
                    en_US: "English",
                    zh_TW: "繁體中文"
                }
                return data[str];
            }

        },
        [],
    )
    const ButtonList: FC = () => {
        const { changeLanguage } = useLanguage();
        const changeLanguageF = useCallback(
            (language: Language) => {
                staticLanguage$.next(language)
                setLocale(language)
                setLocales(language);
                localStorage.setItem("language", language);
                changeLanguage(language)
            },
            [changeLanguage],
        )

        return (
            <div className='button-list'>
                <Button onClick={() => changeLanguageF("en_US")} className='button-list-btn' type='link'>{"English"}</Button>
                <Button onClick={() => changeLanguageF("zh_CN")} className='button-list-btn' type='link'>{"简体中文"}</Button>
                <Button onClick={() => changeLanguageF("zh_TW")} className='button-list-btn' type='link'>{"繁體中文"}</Button>
            </div>
        )
    }

    const eraseFunc = useCallback(
        async () => {
            await gaEventTracker('p_header_button_rc', "p_header_button_rc")
            if (userInfo?.id) {
                navigate("/render-canvas");
            } else {
                if (fingerprint !== "") {
                    let response: any = await get("/user/img/free-token", {}, { "finger-print": fingerprint });
                    if (response.code === 200) {
                        setFreeToken(response.data)
                        if (response.data === 0) {
                            setIsDisableTester(true)
                        }
                    }
                    setIsModalOpen(true);
                }

            }
        },
        [navigate,fingerprint,gaEventTracker,userInfo],
    )

    const handleTester = useCallback(
        async () => {
            await gaEventTracker('p_header_button_tester', "p_header_button_tester")
            setIsModalOpen(false);
            navigate("/render-canvas");
        },
        [navigate, gaEventTracker],
    )

    const handlePlus = useCallback(
        async () => {
            await gaEventTracker('p_header_button_go_login', "p_header_button_go_login")
            setIsModalOpen(false);
            navigate("/login");
        },
        [navigate, gaEventTracker],
    )


    return (
        <div style={{ width: "100%" }}>
            <div className='header-box'>
                <div className='logo'>
                    <img onClick={goHome} src={logo} alt="" />
                    <div className='erase-btn-box'>
                        <Badge size={"small"} count={<i style={{ color: "red", fontSize: 12 }}>Beta</i>} offset={[0, 10]}>
                            <Button className='erase-btn' size="middle" type='link' onClick={eraseFunc}>{t("OBJECT_REMOVAL")}</Button>
                        </Badge>
                    </div>
                    {/* <Button className='erase-btn' type='link'>
                        <Badge size={"small"} count={"Beta"} offset={[10, 0]}>
                            擦除
                        </Badge>
                    </Button> */}
                    {/* {
                        localStorage.getItem(TOKEN_KEY) &&
                        <div className='erase-btn-box'>
                            <Badge size={"small"} count={<i style={{ color: "red", fontSize: 12 }}>Beta</i>} offset={[0, 10]}>
                                <Button className='erase-btn' size="middle" type='link' onClick={eraseFunc}>{t("OBJECT_REMOVAL")}</Button>
                            </Badge>
                        </div>
                    } */}

                </div>

                <div className='user-box'>
                    {
                        userInfo
                            ? (
                                <Dropdown placement="bottom" dropdownRender={() => <BusinessCard />}>
                                    <Space>
                                        <Tooltip title={<p style={{ color: "#474747" }}>{t("PROFILE")}</p>} color="#F7F7F7">
                                            <Avatar className='user-box-avatar' size={35} icon={<UserOutlined />} />
                                        </Tooltip>

                                    </Space>
                                </Dropdown>
                            )
                            : (
                                <>
                                    <div className='navigation-language-box'>
                                        <div className='navigation-language'>
                                            <Dropdown dropdownRender={() => <ButtonList />}>
                                                <Button style={{ color: "#878CB1" }} color='#878CB1' type='link'>
                                                    <span>{languageR(locale)}</span>
                                                </Button>
                                            </Dropdown>
                                        </div>
                                    </div>
                                    {/* <Avatar onClick={goLogin} className='user-box-avatar' size={35} icon={<UserOutlined />} /> */}
                                    <div className='navigation-login-box' onClick={goLogin}>{t("LOGIN")}</div>
                                </>
                            )
                    }
                </div>
            </div>
            <ModalView open={isModalOpen} setOpen={setIsModalOpen} width={802}>
                <div className="home-modal-box">
                    <p className="home-modal-title">{t("WANT_UNLIMITED_USES")} {t("QUICKLY")} <span>{t("UPGRADE_TO_PLUS")}</span> {t("USER")}!</p>
                    <div className="home-modal-btn-box">
                        <Button onClick={handleTester} disabled={isDisableTester} className="home-modal-btn home-modal-btn-tester">
                            <h4>{t("FREE_TRIAL")}</h4>
                            <p>{t("ENJOY_COMPLIMENTARY_CREDITS_DAILY", { n: freeToken||"0" })}</p>
                        </Button>
                        <Button onClick={handlePlus} className="home-modal-btn home-modal-btn-plus">
                            <h4>{t("REGISTER_ACCOUNT")}</h4>
                            <p>{t("CLAIM_100_PROFESSIONAL_RENDERING_CREDITS_FOR_FREE")}</p>
                        </Button>
                    </div>
                    <p className="home-modal-end-text">
                        {t("FOR_MORE_QUESTIONS_PLEASE_CONTACT")} <span>{CONTACT_EMAIL}</span>
                    </p>
                </div>
            </ModalView>
        </div>

    );
};

export default Navigation;

